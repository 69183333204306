<template>
  <div>
    <common-header></common-header>
    <ul class="car-list-box warp"
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="loading"
    infinite-scroll-immediate-check="false"
    infinite-scroll-distance="10">
      <div class="shopcar-admin" @click="adminShopCar">
        <div class="l">
          <i class="icon-shopcar-admin"></i>{{adminShow ? '完成' : '管理足迹'}}
        </div>
        <!-- <div class="" v-if="adminShow">删除失效商品</div> -->
      </div>
      
      <li class="item" v-for="(item, index) in list" :key="index">
        <div class="check" :class="item.check ? 'active' : ''" @click="select(index)" v-if="adminShow"></div>
        <img class="goods-img" @click="adminShow ? '' : $router.push({path: '/shopping/details', query:{id: item.id}})" :key="requestImgUrl(coverImg(item))" v-lazy="requestImgUrl(coverImg(item))" alt="">
        <div class="content" @click="adminShow ? '' : $router.push({path: '/shopping/details', query:{id: item.id}})">
          <div class="name">{{item.name}}</div>
          <div class="ms">{{item.description}}</div>
          <div class="price-box">
            <div class="is-certified" v-if="item.unitPrice < 0 ">{{isCertified()}}</div>
            <div class="price" v-else>
              <span class="unit">￥</span>{{item.unitPrice}}
            </div>
            <div class="purchase jb-r-l" @click="$router.push({path: '/shopping/details', query:{id: item.id}})" v-if="!adminShow">立即购买</div>
          </div>
        </div>
      </li>
    </ul>

    <no-data v-if="nodata"></no-data>
    


    <div class="settlement-box" v-if="adminShow">
      <div class="l" @click="selectAllFun">
        <div class="check" :class="selectAll ? 'active' : ''"></div>
        <div class="txt">全选</div>
      </div>
      <div class="r" @click="claerCollection">
        <div class="del-btn">删除<span v-if="count > 0">({{count}})</span></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { requestImgUrl} from "@/utils/common";
import { isHttp, isCertified} from "@/utils/utils";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.isCertified = ()=> isCertified();
    return {
      adminShow: false,
      nodata: false,
      nomore: false,
      pageNum: 1,
      list: [],
      pages: 1,
      loading: false,
      selectAll: false,
      count: 0,
      ids: [],
    }
  },
  mounted() {
    this.init(this.pageNum);
  },
  methods: {
    // 清空
    claerCollection() {
      this.$MessageBox({
        title: '温馨提示',
        message: `确认要删除这${this.ids.length}种商品?`,
        showCancelButton: true
      }).then((res)=> {
        if (res == 'confirm') {
          this.PostDelFootprint({
            targetId: this.ids.join(',')
          }).then((res)=> {
            this.count = 0;
            this.init(1);
          })
        }
      })
      
    },
    // 全选事件
    selectAllFun() {
      let selectAll = !this.selectAll;
      let arrs = [];
      let ids = [];
      this.list.forEach((e)=> {
        if (selectAll) {
          ids.push(e.id)
        }
        e.check = selectAll;
        arrs.push(e);
      });
      this.count = selectAll ? arrs.length : 0;
      this.ids = ids;
      this.selectAll = selectAll;
      this.list = arrs;
    },
    // 单选
    select(index) {
      // 判断计数值是否等于商品数量，等于就全选
      let item = this.list[index];
      item.check = !item.check;
      this.list[index] = item;

      // 判断是否全选
      let count = 0;
      let ids = [];
      this.list.forEach((e)=> {
        if (e.check) {
          ids.push(e.id)
          count++;
        }
      })
      this.ids = ids;
      this.count = count;
      if (count == this.list.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }

    },
    // 加载分页
    loadMore() {
      let {pageNum, pages} = this;
      pageNum = pageNum + 1;
      if (pageNum > pages) {
        this.nomore = true;
      } else {
        this.pageNum = pageNum;
        this.init(pageNum);
      }
    },
    // 初始化
    init(pageNum, add) {
      this.$Indicator.open({
        text: '加载中...',
        spinnerType: 'snake',
      });
      this.getFootprint({
        pageNum: pageNum,
      }).then((res)=> {
        if (res.data.total > 0) {
          let arrs = [];
          res.data.list.forEach(e => {
            e.check = false;
            arrs.push(e);
          });
          if (add) {
            this.list = this.list.concat(arrs);
            this.pages = res.data.pages;
          } else {
            this.list = arrs;
          }
        } else {
          this.list = [];
          this.nodata = true;
        }
        this.$Indicator.close();
        
      })
    },
    // 规格详情
    coverImg(item) {
      let img = '';
      if (item.specList[0].imgs == '[]') {
        img = this.$transObjUndefined(JSON.parse(item.banner)[0]).url
      } else {
        img = this.$transObjUndefined(JSON.parse(item.specList[0].imgs)[0]).url
      }
      return img;
    },
    ...mapActions('account', [
      'getFootprint',
      'PostDelFootprint',
    ]),
    adminShopCar() {
      this.adminShow = !this.adminShow;
    },
  },
}
</script>
<style lang='less' scoped>
.purchase{
  width: 1.87rem;
  height: .58rem;
  border-radius: .29rem;
  text-align: center;
  font-size: .3rem;
  color: #ffffff;
  line-height: .58rem;
}
.clear-invalid{
  font-size: .3rem;
  color: #727272;
  width: 3.4rem;
  height: .65rem;
  background: #fff;
  text-align: center;
  line-height: .65rem;
  border-radius: .32rem;
  margin: .4rem auto 0;
}
.settlement-box{
  position: fixed;
  height: 1.2rem;
  width: 100%;
  background: #ffffff;
  bottom:0rem;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .2rem;
  .total{
    margin-right: .3rem;
  }
  .del-btn{
    width: 2.4rem;
    height: .78rem;
    border: .02rem solid #ff7241;
    color: #ff7241;
    text-align: center;
    line-height: .78rem;
    border-radius: .4rem;
    font-size: .32rem;
  }
  .go-settlement{
    width: 2.4rem;
    height: .78rem;
    border-radius: .4rem;
    color: #fff;
    font-size: .32rem;
    line-height: .78rem;
    text-align: center;
    font-weight: bold;
    background: rgba(255,113,65,1);/* Old Browsers */
    background: -moz-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,113,65,1)), color-stop(100%, rgba(255,185,139,1)));/* Chrome, Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%); /* IE 10+ */
    background: linear-gradient(to right, rgba(255,113,65,1) 0%, rgba(255,185,139,1) 100%);/* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7141', endColorstr='#ffb98b', GradientType=1 );/* IE6-9 */
  }
  .r{
    color: #262626;
    font-size: .26rem;
    display: flex;
    align-items: center;
    .money{
      font-size: .32rem;
      color: #ff7241;
      margin-left: .08rem;
    }
    .unit{
      font-size: .24rem;
    }
  }
  .l{
    display: flex;
    align-items: center;
    .txt{
      color: #262626;
      font-size: .26rem;
    }
  }
}
.check{
  width: .38rem;
  height: .38rem;
  border-radius: 50%;
  border: .02rem solid #d6d6d6;
  margin-right: .15rem;
  &.active{
    background:url('../../assets/images/img/check-active.png') no-repeat;
    background-size: 100%;
    border: none;
  }
}
.car-list-box{
  background:url('../../assets/images/img/car-bj.png') no-repeat;
  background-size: 100%;
  min-height: 2.5rem;
  .shopcar-admin{
    color: #fff;
    font-size: .3rem;
    padding-top: .4rem;
    padding-bottom: .3rem;
    padding-left: .3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l{
      display: flex;
      align-items: center;
    }
    i{
      margin-right: .1rem;
    }
  }
  .number-box{
    display: flex;
    border: 1px solid #ccc;
    line-height: .48rem;
    text-align: center;
    border-radius: .05rem;
    .btn{
      width: .48rem;
      height: .48rem;
      background: #f7f7f7;
    }
    .val{
      width: .56rem;
    }
  }
  .content{
    flex: 1;
    .name{
      font-size: .3rem;
      color: #262626;
      font-weight: bold;
    }
    .ms{
      font-size: .26rem;
      color: #bfbfbf;
      margin-top: .1rem;
      margin-bottom: .25rem;
    }
  }
  .price-box{
    display: flex;
    justify-content: space-between;
    .price{
      display: flex;
      color: #ff7241;
      font-size: .32rem;
      align-items: center;
      font-weight: bold;
      .unit{
        font-size: .24rem;
      }
    }
  }
  .item{
    background: #fff;
    border-radius: .1rem;
    display: flex;
    align-items: center;
    padding: .3rem .15rem;
    overflow: hidden;
    margin-bottom: .2rem;
    &.invalid{
      position: relative;
      &::after{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .2);
      }
      &::before{
        content: "";
        background:url('../../assets/images/img/selling.png') no-repeat;
        width: 1.06rem;
        height: 1.06rem;
        position: absolute;
        top: .6rem;
        left: .9rem;
        z-index: 2;
        background-size: 100%;
      }
    }
    .goods-img{
      position: relative;
      width: 1.52rem;
      height: 1.52rem;
      border-radius: .05rem;
      margin-right: .2rem;
    }
    
  }
}
</style>